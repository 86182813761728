import React, { useState, useEffect, useLayoutEffect } from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import SEO from 'components/seo'
import { Deco, default as Layout, Header, MainWithMenu, Section } from 'components/layout'
import { LinkArrowIcon, Stones, StoneStyles } from 'components/icons'
import { Nav } from 'components/nav'
import { TechSection } from 'components/icons/Technologies'
import { Unreal, Blender, Cpp, Houdini } from '../../components/icons/Technologies'
import { BackgroundWithGrid } from '../../components/layout'
import { LdJsonLogo, HeaderLink } from '../../components/semanticdata'
import styled from 'styled-components'

import DCityImage16 from '../../assets/digitalcity/16.jpg'
import DCityImage17 from '../../assets/digitalcity/17.jpg'
import DCityImage18 from '../../assets/digitalcity/18.jpg'

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px);
  z-index: 200;
  cursor: pointer;
`

const ModalContent = styled.div`
  background-color: #fefefe;
  overflow: hidden;
  position: relative;
  z-index: 200;
  cursor: default;
  margin-right: 10px;
  margin-left: 10px;
`

const CloseButton = styled.button`
  color: white;
  position: absolute;
  right: 10px;
  font-size: 28px;
  font-weight: bold;
  background: transparent;
  border: none;
  cursor: pointer;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }
`

const LargeImage = styled.div`
  cursor: pointer;
  object-fit: cover;
  border-radius: 0;
  opacity: 1;
`

const TechSectionDiv = styled.div`
  @media (max-width: 1023px) and (min-width: 381px) {
    grid-template-columns: repeat(6, 1fr);
  }
  @media (max-width: 380px) and (min-width: 320px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
const ImageModal = ({ imageUrl, onClose, onNext, onPrevious }) => {
  useEffect(() => {
    const handleKeyDown = e => {
      if (e.key === 'Escape') onClose()
      if (e.key === 'ArrowRight') onNext()
      if (e.key === 'ArrowLeft') onPrevious()
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => window.removeEventListener('keydown', handleKeyDown)
  }, [onClose, onNext, onPrevious])

  return (
    <Modal onClick={onClose}>
      <ModalContent>
        <CloseButton onClick={onClose}>×</CloseButton>
        <img src={imageUrl} alt="Enlarged view" style={{ maxWidth: '100%', maxHeight: '100%' }} onClick={e => e.stopPropagation()} />
      </ModalContent>
    </Modal>
  )
}

const galleryQuery = graphql`
  query {
    allFile(filter: { relativeDirectory: { eq: "digitalcity" } }) {
      nodes {
        name
        publicURL
        childImageSharp {
          fluid(maxWidth: 160, maxHeight: 160, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
export const GalleryComponentDigitalCity = ({ imageCount, ...passThroughProps }) => {
  const data = useStaticQuery(galleryQuery)

  const imageSource = data.allFile.nodes
    .map(node => ({ index: parseInt(node.name), ...node }))
    .filter(node => node.index >= 1 && node.index <= 15)
    .sort((a, b) => a.index - b.index)
    .slice(0, imageCount)

  const [selectedImageIndex, setSelectedImageIndex] = useState(null)

  return (
    <>
      {imageSource.map((node, index) => (
        <a key={node.index} onClick={() => setSelectedImageIndex(index)} style={{ cursor: 'pointer' }}>
          <Img fluid={node.childImageSharp.fluid} alt={`Digital City Image ${node.index}`} {...passThroughProps} />
        </a>
      ))}
      {selectedImageIndex !== null && (
        <ImageModal
          imageUrl={imageSource[selectedImageIndex].publicURL}
          onClose={() => setSelectedImageIndex(null)}
          onNext={() => setSelectedImageIndex(prev => Math.min(prev + 1, imageSource.length - 1))}
          onPrevious={() => setSelectedImageIndex(prev => Math.max(prev - 1, 0))}
        />
      )}
    </>
  )
}

const TechnologiesServicesSectionModal = ({ children }) => {
  const [selectedImage, setSelectedImage] = useState(null)
  const [screenWidth, setScreenWidth] = useState(0)

  const openModal = () => screenWidth >= 640 && setSelectedImage(children.props.src)
  const closeModal = () => setSelectedImage(null)

  useLayoutEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth)
    const handleKeyDown = e => e.key === 'Escape' && closeModal()

    handleResize()
    window.addEventListener('resize', handleResize)
    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('resize', handleResize)
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [])

  return (
    <>
      <div onClick={openModal}>
        <LargeImage>{children}</LargeImage>
      </div>
      {selectedImage && screenWidth >= 640 && (
        <Modal onClick={closeModal}>
          <ModalContent>
            <CloseButton onClick={closeModal}>×</CloseButton>
            <img src={selectedImage} alt="Modal Image" onClick={e => e.stopPropagation()} />
          </ModalContent>
        </Modal>
      )}
    </>
  )
}

const DigitalCityPage = () => (
  <Layout className="bg-primary-2 text-primary-3">
    <SEO title="Urban Tech Innovations" pageUrl="/industries/digitalcity/" description="Revolutionizing urban planning and mobility with Unreal Engine 5-based simulations. Explore lifelike scenarios and tech-driven solutions for complex urban environments." />
    <LdJsonLogo />
    <MainWithMenu className="grid">
      <Header className="pt-6">
        <HeaderLink />
        <div className="pt-12 text-left text-primary-3 text-shadow">
          <h1 className="pt-4 text-4xl lg:text-5xl">Digital City</h1>
        </div>
      </Header>
      <BackgroundWithGrid image="industries-digitalcity-hero-top" gridRow={2} />
      <Deco decoType={2} before inner gridRow={2} className="z-0" />
      <Section className="h-72 mb-10" gridRow={2} />
      <Section className="mb-10" gridRow={3} style={{ marginLeft: 0 }}>
        <div className="mt-6 flex items-baseline">
          <h2 className="text-3xl lg:text-4xl text-primary-1 pb-4">Video Demo</h2>
        </div>
        <div className="grid gap-12 lg:gap-20 grid-cols-2 lg:grid-cols-3">
          <div className="col-span-2 h-56 sm:h-72 lg:h-full">
            <iframe id="ytplayer" title="Industry Video" type="text/html" className="w-full h-full" src="https://www.youtube.com/embed/0IFpVTjtWsI?autoplay=0&origin=https://www.tumba.solutions" frameBorder="0" />
          </div>
          <div className="col-span-2 lg:col-span-1 grid gap-4 grid-rows-3 grid-cols-5 lg:grid-rows-5 lg:grid-cols-3">
            <GalleryComponentDigitalCity imageCount={15} className="object-cover h-full w-full" />
          </div>
        </div>
      </Section>
      <Section className="pt-12 pb-48 grid gap-12 lg:col-span-11" gridRow={4}>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <p className="text-xl lg:text-2xl">
            Utilize the 3D technology of Unreal Engine 5 to depict complex urban landscapes and transportation networks, achieving an exceptionally lifelike visual representation. Delve into diverse viewpoints, angles, and situations and achieve a deeper
            understanding of the spatial connections within the urban setting and the potential effects on traffic conditions.
          </p>
          <p>
            Leverage the capabilities of data to simulate scenarios, incorporating real-world information such as traffic patterns, pedestrian dynamics, and the operations of public transit systems. Using the Unreal Engine's incredible power and the latest
            trends in visual presentation, we can achieve a realistic point of view for all participants in an urban environment. This allows us to emphasize crucial interactions, vehicle behavior, and noteworthy points of interest surrounding a particular
            character.
          </p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <h2 className="text-2xl lg:text-3xl">Technologies</h2>
            <TechSectionDiv className="mt-10 grid grid-cols-2 gap-4">
              <TechSection Icon={Unreal} rotate={0} text="Unreal Engine" />
              <TechSection Icon={Cpp} rotate={-75} text="C++" />
              <TechSection Icon={Blender} rotate={90} text="Blender" />
              <TechSection Icon={Houdini} rotate={0} text="Houdini" />
            </TechSectionDiv>
          </div>
          <div>
            <h2 className="text-2xl lg:text-3xl">Services</h2>
            <h3 className="text-lg pt-10 text-primary-1">The Technology</h3>
            <span className="block mt-5 text-base font-normal tracking-normal">
              Using Unreal Engine 5, we create immersive, realistic interactive experiences with groundbreaking features like Nanite and Lumen that provide a generational leap in visual fidelity. The "Mass Entity" framework simulates city-scaled environments
              and participant interactions. We use Houdini to generate the city and Blender to import custom buildings.
            </span>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-12 lg:pt-10 md:pt-10">
          <div>
            <h3 className="text-lg text-primary-1">A different point of view</h3>
            <span className="block mt-5 text-base font-normal tracking-normal">
              Stepping into the simulation, we assessed performance from the individual's perspective, identifying issues and opportunities for improvement. Observing urban traffic from multiple angles enhances our understanding of complex city dynamics,
              revealing insights into interactions between pedestrians, cyclists, and motorists. This fosters inclusive and efficient mobility solutions.
            </span>
          </div>
          <div>
            <TechnologiesServicesSectionModal>
              <img src={DCityImage18} alt="Digital City Image 18" className="h-auto" />
            </TechnologiesServicesSectionModal>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div>
            <h3 className="text-lg text-primary-1">Scenarios</h3>
            <span className="block mt-5 text-base font-normal tracking-normal">
              After generating the city, we evaluate shared infrastructure. Urban mobility challenges include:
              <ul style={{ listStyleType: 'disc' }} className="ml-5">
                <li>Lack of cycling infrastructure and weather susceptibility</li>
                <li>Bike theft and vandalism</li>
                <li>Limited space in car-centric cities, leading to chaos</li>
                <li>Safety risks from shared infrastructure, undergrounds, and distracted drivers/cyclists</li>
                <li>Negative social attitudes</li>
                <li>Limited parking, traffic congestion, inadequate enforcement, and heightened caution</li>
                <li>Complex road network intricacies</li>
              </ul>
              Seeing traffic this way is crucial for informed decision-making, fostering innovation in transportation planning, and creating efficient and livable cities.
            </span>
          </div>
          <div>
            <TechnologiesServicesSectionModal>
              <img src={DCityImage16} alt="Digital City Image 16" className="h-auto" />
            </TechnologiesServicesSectionModal>
            <TechnologiesServicesSectionModal>
              <img src={DCityImage17} alt="Digital City Image 17" className="h-auto pt-10" />
            </TechnologiesServicesSectionModal>
          </div>
        </div>
      </Section>
      <Deco before after className="bg-primary-1" gridRow={5} />
      <Section className="z-10 pt-12 grid gap-12 grid-cols-3 text-primary-2" gridRow={5}>
        <h2 id="success-stories" className="col-span-3 text-2xl lg:text-3xl">
          Success stories
        </h2>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">I really appreciated your team and the amazing work products you generated for us. It allowed us to generate some exciting and ground-breaking research. Quickly.</p>
          <p className="mt-8">Paul Schmitt, Motion Planning Architect Expressive Robotics</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            The Tumba team helped develop and design 3D environments, physics examples and automotive feature development. Depending on the project, the Motional team would meet with Tumba to provide insights, implementation plan and potential impact of
            upcoming projects.
          </p>
          <p className="mt-8">Chris, Senior UX Prototyper</p>
        </div>
        <div className="col-span-3 sm:col-span-1">
          <p className="mt-5 text-base font-normal tracking-normal">
            We’ve never had an issue and they always did an outstanding job. They’re a very integrated part of our company and when I talk about Tumba, they feel like an extension of my team rather than a contractor. <br />
            What I find most impressive is their ability to deliver a great product, and their communication.
          </p>
          <p className="mt-8">Adam, Head of Autonomy</p>
        </div>
      </Section>
      <Section className="pt-40 pb-12 grid gap-12 grid-cols-6 lg:grid-cols-11" gridRow={6}>
        <div className="col-span-6 lg:col-span-11">
          <Stones className="h-20 overflow-visible" stoneClasses="transform origin-center box-fill" styles={StoneStyles[0]} />
          <p className="pt-6 text-2xl lg:text-3xl">Explore</p>
        </div>
        <Link to="/industries" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">Industries</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/industries/autotech" className="pt-8 col-span-4 flex items-baseline text-primary-1">
          <span className="text-lg">AutoTech</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
        <Link to="/industries/vr" className="pt-8 col-span-3 flex items-baseline text-primary-1">
          <span className="text-lg">VR & 3D</span>
          <LinkArrowIcon className="ml-2 w-4 stroke-current" />
        </Link>
      </Section>
      <BackgroundWithGrid className="relative h-72" image="industries-digitalcity-hero-bottom" gridRow={7}>
        <div className="absolute inset-x-0 bottom-0 h-24" style={{ background: 'linear-gradient(#00edae00, #00edae)' }} />
      </BackgroundWithGrid>
      <Deco decoType={2} after gridRow={6} />
      <Nav className="text-primary-2" gridRow={8} />
    </MainWithMenu>
  </Layout>
)

export default DigitalCityPage
